import userService from '../services/UserService'
import addressService from '../services/AddressService'
import authenticationService from '../services/AuthenticationService'
import centerService from '../services/CenterService'
import locationService from '../services/LocationService'
import delinquentService from '../services/DelinquentService'
import psychotherapy from '../services/PsychotherapyService'
import reportsService from '../services/ReportsService'

let previousRouteName
const allowedRoutes = ['login', 'password reset', 'token request']

const controlAuthentication = (store, route, router) => {
  if (route && route.name && route.name !== previousRouteName) {
    const isAuthenticated = store.getters['store/isAuthenticated']

    if (!isAuthenticated && !allowedRoutes.includes(route.name.toLowerCase())) {
      const forwardToVal = route.query.redirect
      if (forwardToVal && forwardToVal === 'password-reset') router.push('/password-reset')
      else router.push('/login')
    }

    previousRouteName = route.name
  }
}

const service = {}
service.install = (Vue, options) => {
  Vue.mixin({
    beforeCreate () {
      const route = this.$route
      const store = this.$store
      const router = this.$router
      controlAuthentication(store, route, router)
    }
  })

  Vue.prototype.$userService = userService
  Vue.prototype.$addressService = addressService
  Vue.prototype.$authenticationService = authenticationService
  Vue.prototype.$centerService = centerService
  Vue.prototype.$locationService = locationService
  Vue.prototype.$delinquentService = delinquentService
  Vue.prototype.$psychotherapyService = psychotherapy
  Vue.prototype.$reportService = reportsService
}

export default service
