import ApiEndpoint from '../http/EndPoint'
import { axios, handleError, getRequestCancelToken } from '../http/HttpResource'
import Delinquent from '../models/Delinquent'
import qs from 'qs'

let instance = null
const itemsUrl = ApiEndpoint.itemsUrl
const documentTypeUrl = ApiEndpoint.documentTypeUrl
const deviantActUrl = ApiEndpoint.deviantActUrl
const delinquentUrl = ApiEndpoint.delinquentUrl
const apprehensionUrl = ApiEndpoint.apprehensionUrl
const decisionsUrl = ApiEndpoint.decisionsUrl
const screeningCommitteePositionsUrl = ApiEndpoint.screeningCommitteePositionsUrl
const educationLevelsUrl = ApiEndpoint.educationLevelsUrl
const generalScreeningsUrl = ApiEndpoint.generalScreeningsUrl
const medicalScreeningsUrl = ApiEndpoint.medicalScreeningsUrl
const transfersUrl = ApiEndpoint.transfersUrl
const assessmentUrl = ApiEndpoint.assessmentUrl
const assessmentTypesUrl = ApiEndpoint.assessmentTypesUrl
const intakesUrl = ApiEndpoint.intakes
const trainingsUrl = ApiEndpoint.trainings
const trainingClassesUrl = ApiEndpoint.trainingClasses
const graduationsUrl = ApiEndpoint.graduations
const graduandsUrl = ApiEndpoint.graduands
const organizationTypesUrl = ApiEndpoint.organizationTypes
const organizationsUrl = ApiEndpoint.organizations
const specificationUrl = ApiEndpoint.transfersUrl + '/specification'

const createItems = async item => {
  try {
    let createdItem = null
    createdItem = await axios.post(itemsUrl, item, { cancelToken: getRequestCancelToken(createdItem) })
    return createdItem.data
  } catch (error) {
    return handleError(error)
  }
}

const updateItems = async item => {
  try {
    let updatedItem = null
    updatedItem = await axios.put(itemsUrl, item, { cancelToken: getRequestCancelToken(updatedItem) })
    return updatedItem.data
  } catch (error) {
    return handleError(error)
  }
}
const createDocumentType = async type => {
  try {
    let createdDocumentType = null
    createdDocumentType = await axios.post(documentTypeUrl, type, { cancelToken: getRequestCancelToken(createdDocumentType) })
    return createdDocumentType.data
  } catch (error) {
    return handleError(error)
  }
}
const updateDocumentType = async type => {
  try {
    let updatedDocumentType = null
    updatedDocumentType = await axios.put(documentTypeUrl, type, { cancelToken: getRequestCancelToken(updatedDocumentType) })
    return updatedDocumentType.data
  } catch (error) {
    return handleError(error)
  }
}

const createDeviantAct = async deviantAct => {
  try {
    let createdDeviantAct = null
    createdDeviantAct = await axios.post(deviantActUrl, deviantAct, { cancelToken: getRequestCancelToken(createdDeviantAct) })
    return createdDeviantAct.data
  } catch (error) {
    return handleError(error)
  }
}

const updateDeviantAct = async deviantAct => {
  try {
    let updatedDeviantAct = null
    updatedDeviantAct = await axios.put(deviantActUrl, deviantAct, { cancelToken: getRequestCancelToken(updatedDeviantAct) })
    return updatedDeviantAct.data
  } catch (error) {
    return handleError(error)
  }
}

const createDelinquent = async delinquent => {
  try {
    let createDelinquent = null
    if (delinquent.apprehension == null) throw new Error('Apprehension Object can not be null')
    createDelinquent = await axios.post(delinquentUrl, delinquent, { cancelToken: getRequestCancelToken(createDelinquent) })
    return createDelinquent.data
  } catch (error) {
    return handleError(error)
  }
}

const updateDelinquent = async delinquent => {
  try {
    let updateDelinquent = null
    updateDelinquent = await axios.put(delinquentUrl, delinquent, { cancelToken: getRequestCancelToken(updateDelinquent) })
    return updateDelinquent.data
  } catch (error) {
    return handleError(error)
  }
}

const createApprehension = async apprehension => {
  try {
    let createApprehension = null
    createApprehension = await axios.post(apprehensionUrl, apprehension, { cancelToken: getRequestCancelToken(createApprehension) })
    return createApprehension.data
  } catch (error) {
    return handleError(error)
  }
}

// const updateApprehension = async apprehension => {
//   try {
//     let updateApprehension = null
//     updateApprehension = await axios.put(apprehensionUrl, apprehension, { cancelToken: getRequestCancelToken(updateApprehension) })
//     return updateApprehension.data
//   } catch (error) {
//     return handleError(error)
//   }
// }

const createGeneralScreenings = async generalScreenings => {
  try {
    let createGeneralScreenings = null
    createGeneralScreenings = await axios.post(generalScreeningsUrl, generalScreenings, { cancelToken: getRequestCancelToken(createGeneralScreenings) })
    return createGeneralScreenings.data
  } catch (error) {
    return handleError(error)
  }
}
const updateGeneralScreenings = async generalScreenings => {
  try {
    let updateGeneralScreenings = null
    updateGeneralScreenings = await axios.put(generalScreeningsUrl, generalScreenings, { cancelToken: getRequestCancelToken(updateGeneralScreenings) })
    return updateGeneralScreenings.data
  } catch (error) {
    return handleError(error)
  }
}

const createMedicalScreenings = async medicalScreenings => {
  try {
    let createMedicalScreenings = null
    createMedicalScreenings = await axios.post(medicalScreeningsUrl, medicalScreenings, { cancelToken: getRequestCancelToken(createMedicalScreenings) })
    return createMedicalScreenings.data
  } catch (error) {
    return handleError(error)
  }
}

const createRequestForApproval = async requestForApproval => {
  try {
    let createRequestForApproval = null
    createRequestForApproval = await axios.put(`${generalScreeningsUrl}/approvals/requests`, requestForApproval, { cancelToken: getRequestCancelToken(createRequestForApproval) })
    return createRequestForApproval.data
  } catch (error) {
    return handleError(error)
  }
}

const createApproval = async Approval => {
  try {
    let createApproval = null
    createApproval = await axios.put(`${generalScreeningsUrl}/approvals`, Approval, { cancelToken: getRequestCancelToken(createApproval) })
    return createApproval.data
  } catch (error) {
    return handleError(error)
  }
}
const createTransferInit = async (TransferInit, released) => {
  try {
    let createTransferInit = null
    createTransferInit = await axios.put(transfersUrl, TransferInit, {
      params: {
        released: released
      },
      cancelToken: getRequestCancelToken(createTransferInit)
    })
    return createTransferInit.data
  } catch (error) {
    return handleError(error)
  }
}

const createConfirmReceive = async ConfirmReceive => {
  try {
    let createConfirmReceive = null
    createConfirmReceive = await axios.post(transfersUrl, ConfirmReceive, { cancelToken: getRequestCancelToken(createConfirmReceive) })
    return createConfirmReceive.data
  } catch (error) {
    return handleError(error)
  }
}

const createEnrolIntake = async (id, EnrolIntake) => {
  try {
    let createEnrolIntake = null
    createEnrolIntake = await axios.put(`${intakesUrl}/${id}/enrolments`, EnrolIntake, { cancelToken: getRequestCancelToken(createEnrolIntake) })
    return createEnrolIntake.data
  } catch (error) {
    return handleError(error)
  }
}

const createEnrolments = async (id, Enrolments) => {
  try {
    let createEnrolments = null
    createEnrolments = await axios.post(`${trainingClassesUrl}/${id}/enrolments`, Enrolments, { cancelToken: getRequestCancelToken(createEnrolments) })
    return createEnrolments.data
  } catch (error) {
    return handleError(error)
  }
}

const updateEnrolments = async (id, Enrolments) => {
  try {
    let updateEnrolments = null
    updateEnrolments = await axios.put(`${trainingClassesUrl}/${id}/enrolments`, Enrolments, { cancelToken: getRequestCancelToken(updateEnrolments) })
    return updateEnrolments.data
  } catch (error) {
    return handleError(error)
  }
}
const createAssessment = async Assessment => {
  try {
    let createdAssessment = null
    createdAssessment = await axios.post(assessmentUrl, Assessment, { cancelToken: getRequestCancelToken(createdAssessment) })
    return createdAssessment.data
  } catch (error) {
    return handleError(error)
  }
}
const updateAssessment = async Assessment => {
  try {
    let updatedAssessment = null
    updatedAssessment = await axios.put(assessmentUrl, Assessment, { cancelToken: getRequestCancelToken(updatedAssessment) })
    return updatedAssessment.data
  } catch (error) {
    return handleError(error)
  }
}

const createGraduation = async Graduation => {
  try {
    let createdGraduation = null
    createdGraduation = await axios.post(graduationsUrl, Graduation, { cancelToken: getRequestCancelToken(createdGraduation) })
    return createdGraduation.data
  } catch (error) {
    return handleError(error)
  }
}
const updateGraduation = async Graduation => {
  try {
    let updatedGraduation = null
    updatedGraduation = await axios.put(graduationsUrl, Graduation, { cancelToken: getRequestCancelToken(updatedGraduation) })
    return updatedGraduation.data
  } catch (error) {
    return handleError(error)
  }
}
const createTrainingClasses = async trainingClasses => {
  try {
    let createTrainingClasses = null
    createTrainingClasses = await axios.post(trainingClassesUrl, trainingClasses, { cancelToken: getRequestCancelToken(createTrainingClasses) })
    return createTrainingClasses.data
  } catch (error) {
    return handleError(error)
  }
}
const updateTrainingClasses = async trainingClasses => {
  try {
    let updateTrainingClasses = null
    updateTrainingClasses = await axios.put(trainingClassesUrl, trainingClasses, { cancelToken: getRequestCancelToken(updateTrainingClasses) })
    return updateTrainingClasses.data
  } catch (error) {
    return handleError(error)
  }
}
const createGraduationList = async (id, GraduationList) => {
  try {
    let createGraduationList = null
    createGraduationList = await axios.put(`${graduationsUrl}/${id}/graduands`, GraduationList, { cancelToken: getRequestCancelToken(createGraduationList) })
    return createGraduationList.data
  } catch (error) {
    return handleError(error)
  }
}
const createGraduationApproval = async Approval => {
  try {
    let createGraduationApproval = null
    createGraduationApproval = await axios.put(`${graduationsUrl}/approvals`, Approval, { cancelToken: getRequestCancelToken(createGraduationApproval) })
    return createGraduationApproval.data
  } catch (error) {
    return handleError(error)
  }
}
const createReleaseGraduate = async (ReleaseGraduate) => {
  try {
    let createReleaseGraduate = null
    createReleaseGraduate = await axios.put(`${graduandsUrl}/releases`, ReleaseGraduate, { cancelToken: getRequestCancelToken(createReleaseGraduate) })
    return createReleaseGraduate.data
  } catch (error) {
    return handleError(error)
  }
}
const createOrganization = async Organization => {
  try {
    let createdOrganization = null
    createdOrganization = await axios.post(organizationsUrl, Organization, { cancelToken: getRequestCancelToken(createdOrganization) })
    return createdOrganization.data
  } catch (error) {
    return handleError(error)
  }
}
const updateOrganization = async Organization => {
  try {
    let updatedOrganization = null
    updatedOrganization = await axios.put(organizationsUrl, Organization, { cancelToken: getRequestCancelToken(updatedOrganization) })
    return updatedOrganization.data
  } catch (error) {
    return handleError(error)
  }
}

class _DelinqentService {
  constructor () {
    if (!instance) instance = this
    return instance
  }

  async saveItems (itemObj) {
    let response
    if (itemObj == null) throw new Error('Invalid item object')
    else if (itemObj.id) {
      response = await updateItems(itemObj)
      return response
    } else {
      response = await createItems(itemObj)
      return response
    }
  }

  async saveDocumentType (documentTypeObj) {
    let response
    if (documentTypeObj == null) throw new Error('Invalid documentType object')
    else if (documentTypeObj.id) {
      response = await updateDocumentType(documentTypeObj)
      return response
    } else {
      response = await createDocumentType(documentTypeObj)
      return response
    }
  }

  async saveDeviantAct (deviantActObj) {
    let response
    if (deviantActObj == null) throw new Error('Invalid deviantAct object')
    else if (deviantActObj.id) {
      response = await updateDeviantAct(deviantActObj)
      return response
    } else {
      response = await createDeviantAct(deviantActObj)
      return response
    }
  }

  async fetchAllItems (collapse = false) {
    try {
      let itemObj = null
      itemObj = await axios.get(itemsUrl, {
        params: collapse ? { collapse: true } : {},
        cancelToken: getRequestCancelToken(itemObj)
      })
      return itemObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllItemsList () {
    try {
      let itemObj = null
      itemObj = await axios.get(`${itemsUrl}/names`, { cancelToken: getRequestCancelToken(itemObj) })
      return itemObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchSingleItem (id) {
    try {
      let itemObj = null
      itemObj = await axios.get(`${itemsUrl}/${id}`, { cancelToken: getRequestCancelToken(itemObj) })
      return itemObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteItem (id) {
    try {
      let response = null
      response = await axios.delete(`${itemsUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllDocumentType (page, size) {
    try {
      let documentTypeObj = null
      documentTypeObj = await axios.get(documentTypeUrl, {
        params: {
          page: page,
          size: size
        },
        cancelToken: getRequestCancelToken(documentTypeObj)
      })
      return documentTypeObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllDocumentTypeList () {
    try {
      let documentTypeObj = null
      documentTypeObj = await axios.get(`${documentTypeUrl}/names`, { cancelToken: getRequestCancelToken(documentTypeObj) })
      return documentTypeObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchSingleDocumentType (id) {
    try {
      let documentTypeObj = null
      documentTypeObj = await axios.get(`${documentTypeUrl}/${id}`, { cancelToken: getRequestCancelToken(documentTypeObj) })
      return documentTypeObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteDocumentType (id) {
    try {
      let response = null
      response = await axios.delete(`${documentTypeUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllDeviantAct (page, size, sort) {
    try {
      let deviantActObj = null
      deviantActObj = await axios.get(deviantActUrl, {
        params: {
          page: page,
          size: size,
          sort: 'act,desc'
        },
        cancelToken: getRequestCancelToken(deviantActObj)
      })
      return deviantActObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllDeviantActList () {
    try {
      let deviantActObj = null
      deviantActObj = await axios.get(`${deviantActUrl}/acts`, { cancelToken: getRequestCancelToken(deviantActObj) })
      return deviantActObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchSingleDeviantAct (id) {
    try {
      let deviantActObj = null
      deviantActObj = await axios.get(`${deviantActUrl}/${id}`, { cancelToken: getRequestCancelToken(deviantActObj) })
      return deviantActObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteDeviantAct (id) {
    try {
      let response = null
      response = await axios.delete(`${deviantActUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveDelinquent (delinquentObj) {
    let response
    if (delinquentObj == null) throw new Error('Invalid delinquent object')
    else if (delinquentObj.id) {
      response = await updateDelinquent(delinquentObj)
      return response
    } else {
      response = await createDelinquent(delinquentObj)
      return response
    }
  }

  async deleteApprehension (id) {
    try {
      let response = null
      response = await axios.delete(`${apprehensionUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveApprehension (apprehensionObj) {
    let response
    if (apprehensionObj == null) throw new Error('Invalid apprehension object')
    // else if (apprehensionObj.id) {
    //   response = await updateApprehension(apprehensionObj)
    //   return response
    else {
      response = await createApprehension(apprehensionObj)
      return response
    }
  }

  async loadDelinquentIdentification (id, expand, state) {
    try {
      let delinquentObj = null
      const delinquentObject = new Delinquent()
      delinquentObj = await axios.get(`${delinquentUrl}/${id}`, {
        params: {
          expand: expand,
          state: state
        },
        cancelToken: getRequestCancelToken(delinquentObj)
      })
      delinquentObject.id = delinquentObj.data.id ? delinquentObj.data.id : null
      delinquentObject.firstName = delinquentObj.data.firstName ? delinquentObj.data.firstName : null
      delinquentObject.lastName = delinquentObj.data.lastName ? delinquentObj.data.lastName : null
      delinquentObject.nid = delinquentObj.data.nid ? delinquentObj.data.nid : null
      delinquentObject.fatherName = delinquentObj.data.fatherName ? delinquentObj.data.fatherName : null
      delinquentObject.motherName = delinquentObj.data.motherName ? delinquentObj.data.motherName : null
      delinquentObject.gender = delinquentObj.data.gender ? delinquentObj.data.gender : null
      delinquentObject.maritalStatus = delinquentObj.data.maritalStatus ? delinquentObj.data.maritalStatus : null
      delinquentObject.guardian = delinquentObj.data.guardian ? delinquentObj.data.guardian : null
      delinquentObject.guardianPhone = delinquentObj.data.guardianPhone ? delinquentObj.data.guardianPhone : null
      delinquentObject.guardianEmail = delinquentObj.data.guardianEmail ? delinquentObj.data.guardianEmail : null
      delinquentObject.picture = delinquentObj.data.picture ? delinquentObj.data.picture : null
      delinquentObject.fingerprint = delinquentObj.data.fingerprint ? delinquentObj.data.fingerprint : null
      delinquentObject.dateOfBirth = delinquentObj.data.dateOfBirth ? delinquentObj.data.dateOfBirth : null
      delinquentObject.registrationDate = delinquentObj.data.registrationDate ? delinquentObj.data.registrationDate : null
      delinquentObject.phone = delinquentObj.data.phone ? delinquentObj.data.phone : null
      delinquentObject.passportNumber = delinquentObj.data.passportNumber ? delinquentObj.data.passportNumber : null
      delinquentObject.spouseName = delinquentObj.data.spouseName ? delinquentObj.data.spouseName : null
      delinquentObject.educationLevel = delinquentObj.data.educationLevel ? delinquentObj.data.educationLevel : null
      delinquentObject.homeless = delinquentObj.data.homeless ? delinquentObj.data.homeless : null
      delinquentObject.specificDisease = delinquentObj.data.specificDisease ? delinquentObj.data.specificDisease : null
      delinquentObject.fatherVitalStatus = delinquentObj.data.fatherVitalStatus ? delinquentObj.data.fatherVitalStatus : null
      delinquentObject.motherVitalStatus = delinquentObj.data.motherVitalStatus ? delinquentObj.data.motherVitalStatus : null
      delinquentObject.placeOfBirth = delinquentObj.data.placeOfBirth ? delinquentObj.data.placeOfBirth : null
      delinquentObj.data.apprehension = delinquentObj.data.apprehension ? delinquentObj.data.apprehension : {}
      delinquentObject.apprehension.id = delinquentObj.data.apprehension.id ? delinquentObj.data.apprehension.id : null
      delinquentObject.apprehension.apprehensionDate = delinquentObj.data.apprehension.apprehensionDate ? delinquentObj.data.apprehension.apprehensionDate : null
      delinquentObject.apprehension.apprehensionWay = delinquentObj.data.apprehension.apprehensionWay ? delinquentObj.data.apprehension.apprehensionWay : null
      delinquentObject.apprehension.apprehenderName = delinquentObj.data.apprehension.apprehenderName ? delinquentObj.data.apprehension.apprehenderName : null
      delinquentObject.apprehension.apprehenderPhone = delinquentObj.data.apprehension.apprehenderPhone ? delinquentObj.data.apprehension.apprehenderPhone : null
      delinquentObject.apprehension.apprehenderFunction = delinquentObj.data.apprehension.apprehenderFunction ? delinquentObj.data.apprehension.apprehenderFunction : null
      delinquentObject.apprehension.delinquentResidence = delinquentObj.data.apprehension.delinquentResidence ? delinquentObj.data.apprehension.delinquentResidence : null
      delinquentObject.apprehension.apprehensionPlace = delinquentObj.data.apprehension.apprehensionPlace ? delinquentObj.data.apprehension.apprehensionPlace : null
      delinquentObject.apprehension.documentTypes = delinquentObj.data.apprehension.documentTypes ? delinquentObj.data.apprehension.documentTypes : null
      delinquentObject.apprehension.items = delinquentObj.data.apprehension.items ? delinquentObj.data.apprehension.items : null
      delinquentObject.apprehension.deviantActs = delinquentObj.data.apprehension.deviantActs ? delinquentObj.data.apprehension.deviantActs : null
      delinquentObj.data.latestScreening = delinquentObj.data.latestScreening ? delinquentObj.data.latestScreening : {}
      delinquentObject.latestScreening = delinquentObj.data.latestScreening ? delinquentObj.data.latestScreening : null
      delinquentObject.latestScreening.id = delinquentObj.data.latestScreening.id ? delinquentObj.data.latestScreening.id : null
      delinquentObject.latestScreening.meetingDate = delinquentObj.data.latestScreening.meetingDate ? delinquentObj.data.latestScreening.meetingDate : null
      delinquentObject.latestScreening.decision = delinquentObj.data.latestScreening.decision ? delinquentObj.data.latestScreening.decision : null
      delinquentObject.latestScreening.decisionReason = delinquentObj.data.latestScreening.decisionReason ? delinquentObj.data.latestScreening.decisionReason : null
      delinquentObject.latestScreening.transferCenter = delinquentObj.data.latestScreening.transferCenter ? delinquentObj.data.latestScreening.transferCenter : null
      delinquentObject.latestScreening.committeeMembers = delinquentObj.data.latestScreening.committeeMembers ? delinquentObj.data.latestScreening.committeeMembers : null
      delinquentObject.latestScreening.delinquentPointOfView = delinquentObj.data.latestScreening.delinquentPointOfView ? delinquentObj.data.latestScreening.delinquentPointOfView : null
      delinquentObject.latestScreening.guardianPointOfView = delinquentObj.data.latestScreening.guardianPointOfView ? delinquentObj.data.latestScreening.guardianPointOfView : null
      delinquentObject.latestScreening.approvalStatus = delinquentObj.data.latestScreening.approvalStatus ? delinquentObj.data.latestScreening.approvalStatus : null
      delinquentObject.latestScreening.approver = delinquentObj.data.latestScreening.approver ? delinquentObj.data.latestScreening.approver : null
      delinquentObject.latestScreening.approvalDate = delinquentObj.data.latestScreening.approvalDate ? delinquentObj.data.latestScreening.approvalDate : null
      delinquentObj.data.latestTransfer = delinquentObj.data.latestTransfer ? delinquentObj.data.latestTransfer : {}
      delinquentObject.latestTransfer.id = delinquentObj.data.latestTransfer.id ? delinquentObj.data.latestTransfer.id : null
      delinquentObject.latestTransfer.eligibilityState = delinquentObj.data.latestTransfer.eligibilityState ? delinquentObj.data.latestTransfer.eligibilityState : null
      delinquentObject.latestTransfer.eligibilityComment = delinquentObj.data.latestTransfer.eligibilityComment ? delinquentObj.data.latestTransfer.eligibilityComment : null
      delinquentObject.latestTransfer.receiver = delinquentObj.data.latestTransfer.receiver ? delinquentObj.data.latestTransfer.receiver : null
      delinquentObject.latestTransfer.arrivalDate = delinquentObj.data.latestTransfer.arrivalDate ? delinquentObj.data.latestTransfer.arrivalDate : null
      delinquentObject.latestTransfer.recordDate = delinquentObj.data.latestTransfer.recordDate ? delinquentObj.data.latestTransfer.recordDate : null
      delinquentObject.latestTransfer.state = delinquentObj.data.latestTransfer.state ? delinquentObj.data.latestTransfer.state : null
      delinquentObject.latestMedicalTestResults = delinquentObj.data.latestMedicalTestResults ? delinquentObj.data.latestMedicalTestResults : null
      return delinquentObject
    } catch (error) {
      return handleError(error)
    }
  }

  async loadDelinquentIdentificationNid (nid) {
    try {
      let delinquentObj = null
      delinquentObj = await axios.get(`${delinquentUrl}/${nid}`, { cancelToken: getRequestCancelToken(delinquentObj) })
      return delinquentObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async dischargerDelinquent (payload) {
    try {
      let response = null
      response = await axios.put(transfersUrl + '/' + payload.id + '/releases', { releaseDate: payload.releaseDate, releaseReason: payload.reason, releaseComment: payload.comment }, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllDelinquents (expand, page, size, sort) {
    try {
      let delinquentObj = null
      delinquentObj = await axios.get(delinquentUrl, {
        params: expand ? {
          expand: expand,
          page: page,
          size: size,
          sort: 'registrationDate,desc'
        } : {
          page: page,
          size: size,
          sort: 'registrationDate,desc'
        },
        cancelToken: getRequestCancelToken(delinquentObj)
      })
      return delinquentObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllApprehensions (expand, page, size, sort) {
    try {
      let apprehensionObj = null
      apprehensionObj = await axios.get(apprehensionUrl, {
        params: {
          expand: expand,
          page: page,
          size: size,
          sort: 'apprehension.apprehensionDate,desc'
        },
        cancelToken: getRequestCancelToken(apprehensionObj)
      })
      return apprehensionObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchDecisions () {
    try {
      let decisionsObj = null
      decisionsObj = await axios.get(`${decisionsUrl}/names`, { cancelToken: getRequestCancelToken(decisionsObj) })
      return decisionsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchDecisionsObjects () {
    try {
      let decisionsObj = null
      decisionsObj = await axios.get(decisionsUrl, { cancelToken: getRequestCancelToken(decisionsObj) })
      return decisionsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchCommitteeMembersPositions () {
    try {
      let screeningCommitteePositionsObj = null
      screeningCommitteePositionsObj = await axios.get(screeningCommitteePositionsUrl, { cancelToken: getRequestCancelToken(screeningCommitteePositionsObj) })
      return screeningCommitteePositionsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchEducationLevels () {
    try {
      let educationLevelsObj = null
      educationLevelsObj = await axios.get(educationLevelsUrl, { cancelToken: getRequestCancelToken(educationLevelsObj) })
      return educationLevelsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveGeneralScreenings (generalScreeningsObj) {
    let response
    if (generalScreeningsObj == null) throw new Error('Invalid generalScreenings object')
    else if (generalScreeningsObj.latestScreening.id) {
      response = await updateGeneralScreenings(generalScreeningsObj)
      return response
    } else {
      response = await createGeneralScreenings(generalScreeningsObj)
      return response
    }
  }

  async deleteGeneralScreening (id) {
    try {
      let response = null
      response = await axios.delete(`${generalScreeningsUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveMedicalScreenings (medicalScreeningsObj) {
    let response
    if (medicalScreeningsObj == null) throw new Error('Invalid medicalScreenings object')
    else {
      response = await createMedicalScreenings(medicalScreeningsObj)
      return response
    }
  }

  async deleteMedicalScreening (id) {
    try {
      let response = null
      response = await axios.delete(`${medicalScreeningsUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTransfersAnyParamsStatesArray (params) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(transfersUrl, {
        params: params,
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTransfersAnyParams (params) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(transfersUrl, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params)
        },
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTransfersSpecification (params) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(specificationUrl, {
        params: params,
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllTransfersSpecification (delinquentFirstName, delinquentLastName, placeOfBirthId, residenceId, recordDates, arrivalDates, exitDates, released, states, eligibilityStates, intakeId, intakeSet, decisionIds, physicallyScreened, screeningApprovalStatuses, onGraduationList, graduationId, expand, graduationApprovalStatuses, postRehabDestinationId, hasOccupation, organizationId, page, size, sort) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(`${transfersUrl}/specification`, {
        params: {
          delinquentFirstName: delinquentFirstName,
          delinquentLastName: delinquentLastName,
          placeOfBirthId: placeOfBirthId,
          residenceId: residenceId,
          recordDates: recordDates,
          arrivalDates: arrivalDates,
          exitDates: exitDates,
          released: released,
          states: states,
          eligibilityStates: eligibilityStates,
          intakeId: intakeId,
          intakeSet: intakeSet,
          decisionIds: decisionIds,
          physicallyScreened: physicallyScreened,
          screeningApprovalStatuses: screeningApprovalStatuses,
          onGraduationList: onGraduationList,
          graduationId: graduationId,
          expand: expand,
          graduationApprovalStatuses: graduationApprovalStatuses,
          postRehabDestinationId: postRehabDestinationId,
          hasOccupation: hasOccupation,
          organizationId: organizationId,
          page: page,
          size: size,
          sort: sort
        },
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllTransfers (expand, physicallyScreenedOnly, approvalStatus, decisionIds, state, page, size, sort) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(transfersUrl + '/specification', {
        params: {
          expand: expand,
          physicallyScreened: physicallyScreenedOnly,
          screeningApprovalStatuses: approvalStatus,
          decisionIds: decisionIds,
          states: state,
          page: page,
          size: size,
          sort: sort
        },
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllTransfersByNidOrName ({ nid, name }) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(transfersUrl + '/specification', {
        params: {
          delinquentNid: nid,
          delinquentName: name,
          states: 'ADMITTED,REHABILITATION,TRANSFER_INITIATED'
        },
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTransfersFromTo (dateFromTo) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(transfersUrl, {
        params: {
          arrival_date_between: dateFromTo
        },
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllTransfersForReceive (expand) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(`${transfersUrl}/initiated`, {
        params: {
          expand: expand
        },
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async requestForApproval (requestForApprovalObj) {
    let response
    if (requestForApprovalObj == null) throw new Error('Invalid requestForApproval object')
    else {
      response = await createRequestForApproval(requestForApprovalObj)
      return response
    }
  }

  async approval (ApprovalObj) {
    let response
    if (ApprovalObj == null) throw new Error('Invalid Approval object')
    else {
      response = await createApproval(ApprovalObj)
      return response
    }
  }

  async confirmTransfer (confirmTransferObj, released) {
    let response
    if (confirmTransferObj == null) throw new Error('Invalid confirmTransfer object')
    else {
      response = await createTransferInit(confirmTransferObj, released)
      return response
    }
  }

  async confirmReceive (confirmReceiveObj) {
    let response
    if (confirmReceiveObj == null) throw new Error('Invalid confirmReceive object')
    else {
      response = await createConfirmReceive(confirmReceiveObj)
      return response
    }
  }

  async searchName (expand, state, delinquentName) {
    try {
      let transfersObj = null
      transfersObj = await axios.get(transfersUrl + '/specification', {
        params: {
          expand: expand,
          states: state,
          delinquentName: delinquentName
        },
        cancelToken: getRequestCancelToken(transfersObj)
      })
      return transfersObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAssessmentTypes () {
    try {
      let assessmentTypeObj = null
      assessmentTypeObj = await axios.get(`${assessmentTypesUrl}/names`, { cancelToken: getRequestCancelToken(assessmentTypeObj) })
      return assessmentTypeObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchDelinquentAssessments (id) {
    try {
      let assessmentsObj = null
      assessmentsObj = await axios.get(`${transfersUrl}/${id}/assessments`, { cancelToken: getRequestCancelToken(assessmentsObj) })
      return assessmentsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveAssessment (assessmentObj) {
    let response
    if (assessmentObj == null) throw new Error('Invalid assessment object')
    else if (assessmentObj.id) {
      response = await updateAssessment(assessmentObj)
      return response
    } else {
      response = await createAssessment(assessmentObj)
      return response
    }
  }

  async fetchAssessmentById (id) {
    try {
      let assessmentObj = null
      assessmentObj = await axios.get(`${assessmentUrl}/${id}`, { cancelToken: getRequestCancelToken(assessmentObj) })
      return assessmentObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteAssessment (id) {
    try {
      let response = null
      response = await axios.delete(`${assessmentUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchIntakes (state) {
    try {
      let intakesObj = null
      intakesObj = await axios.get(`${intakesUrl}/list`, {
        params: {
          state: state
        },
        cancelToken: getRequestCancelToken(intakesObj)
      })
      return intakesObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async enrolToIntake (id, intakesObj) {
    let response
    if (intakesObj == null) throw new Error('Invalid confirmReceive object')
    else {
      response = await createEnrolIntake(id, intakesObj)
      return response
    }
  }

  async fetchTrainingNames () {
    try {
      let trainingsObj = null
      trainingsObj = await axios.get(`${trainingsUrl}/list`, { cancelToken: getRequestCancelToken(trainingsObj) })
      return trainingsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTrainings () {
    try {
      let trainingsObj = null
      trainingsObj = await axios.get(trainingsUrl, { params: { paged: false }, cancelToken: getRequestCancelToken(trainingsObj) })
      return trainingsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTrainingLevelsById (id) {
    try {
      let trainingLevelObj = null
      trainingLevelObj = await axios.get(`${trainingsUrl}/${id}/levels`, { cancelToken: getRequestCancelToken(trainingLevelObj) })
      return trainingLevelObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTrainingClasses (intake, expand, training, page, size) {
    try {
      let trainingClassesObj = null
      trainingClassesObj = await axios.get(`${trainingClassesUrl}`, {
        params: {
          intake: intake,
          expand: expand,
          training: training,
          page: page,
          size: size
        },
        cancelToken: getRequestCancelToken(trainingClassesObj)
      })
      return trainingClassesObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveTrainingClasses (trainingClassesObj) {
    let response
    if (trainingClassesObj == null) throw new Error('Invalid trainingClasses object')
    else if (trainingClassesObj.id) {
      response = await updateTrainingClasses(trainingClassesObj)
      return response
    } else {
      response = await createTrainingClasses(trainingClassesObj)
      return response
    }
  }

  async completeTrainingClass (id, endDate) {
    try {
      let response = null
      response = await axios.patch(`${trainingClassesUrl}/${id}?end_date=${endDate}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchTrainingClassById (id, expand) {
    try {
      let trainingClassObj = null
      trainingClassObj = await axios.get(`${trainingClassesUrl}/${id}`, {
        params: {
          expand: expand
        },
        cancelToken: getRequestCancelToken(trainingClassObj)
      })
      return trainingClassObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteTrainingClass (id) {
    try {
      let response = null
      response = await axios.delete(`${trainingClassesUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async enrolTrainees (id, enrolmentsObj) {
    let response
    if (enrolmentsObj == null) throw new Error('Invalid confirmReceive object')
    else {
      response = await createEnrolments(id, enrolmentsObj)
      return response
    }
  }

  async updateTrainee (id, enrolmentsObj) {
    let response
    if (enrolmentsObj == null) throw new Error('Invalid confirmReceive object')
    else {
      response = await updateEnrolments(id, enrolmentsObj)
      return response
    }
  }

  async deleteEnrolment (classId, enrolmentId) {
    try {
      let response = null
      response = await axios.delete(`${trainingClassesUrl}/${classId}/enrolments/${enrolmentId}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchGraduations (from, to, state, name, approvalStatuses) {
    try {
      let graduationsObj = null
      graduationsObj = await axios.get(`${graduationsUrl}`, {
        params: {
          from: from,
          to: to,
          state: state,
          name: name,
          approvalStatuses: approvalStatuses
        },
        cancelToken: getRequestCancelToken(graduationsObj)
      })
      return graduationsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveGraduation (graduationObj) {
    let response
    if (graduationObj == null) throw new Error('Invalid graduation object')
    else if (graduationObj.id) {
      response = await updateGraduation(graduationObj)
      return response
    } else {
      response = await createGraduation(graduationObj)
      return response
    }
  }

  async deleteGraduation (id) {
    try {
      let response = null
      response = await axios.delete(`${graduationsUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchGraduationById (id, expand) {
    try {
      let graduationObj = null
      graduationObj = await axios.get(`${graduationsUrl}/${id}`, {
        params: {
          expand: expand
        },
        cancelToken: getRequestCancelToken(graduationObj)
      })
      return graduationObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async addGraduantsToList (id, graduantsObj) {
    let response
    if (graduantsObj == null) throw new Error('Invalid confirmReceive object')
    else {
      response = await createGraduationList(id, graduantsObj)
      return response
    }
  }

  async requestGraduationApproval (id) {
    try {
      let graduationObj = null
      graduationObj = await axios.put(`${graduationsUrl}/${id}/approvals/requests`, { cancelToken: getRequestCancelToken(graduationObj) })
      return graduationObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async removeGraduand (graduandId, transferId) {
    try {
      let response = null
      response = await axios.delete(`${graduationsUrl}/${graduandId}/graduands/${transferId}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async approveGraduation (ApprovalObj) {
    let response
    if (ApprovalObj == null) throw new Error('Invalid Approval object')
    else {
      response = await createGraduationApproval(ApprovalObj)
      return response
    }
  }

  // async releaseGraduates (selectedObj) {
  //   try {
  //     let response = null
  //     response = await axios.put(`${graduandsUrl}/releases`, selectedObj, { cancelToken: getRequestCancelToken(response) })
  //     return response.data
  //   } catch (error) {
  //     handleError(error)
  //   }
  // }

  async releaseGraduates (selectedObj) {
    let response
    if (selectedObj == null) throw new Error('Invalid releaseGraduates object')
    else {
      response = await createReleaseGraduate(selectedObj)
      return response
    }
  }

  async fetchOrganizationTypes () {
    try {
      let response = null
      response = await axios.get(organizationTypesUrl, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchOrganizations (name, typeId, locationId, address, contactPerson, page, size, sort) {
    try {
      let response = null
      response = await axios.get(organizationsUrl, {
        params: {
          name: name,
          typeId: typeId,
          locationId: locationId,
          address: address,
          contactPerson: contactPerson,
          page: page,
          size: size,
          sort: sort
        },
        cancelToken: getRequestCancelToken(response)
      })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchOrganizationById (id, expand) {
    try {
      let response = null
      response = await axios.get(`${organizationsUrl}/${id}`, {
        params: {
          expand: expand
        },
        cancelToken: getRequestCancelToken(response)
      })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteOrganization (id) {
    try {
      let response = null
      response = await axios.delete(`${organizationsUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveOrganization (organizationObj) {
    let response
    if (organizationObj == null) throw new Error('Invalid organization object')
    else if (organizationObj.id) {
      response = await updateOrganization(organizationObj)
      return response
    } else {
      response = await createOrganization(organizationObj)
      return response
    }
  }

  async addGraduatesToOrganization (id, selectedObj) {
    try {
      let response = null
      response = await axios.post(`${organizationsUrl}/${id}/graduands`, selectedObj, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      handleError(error)
    }
  }

  async removeGraduateFromOrganization (organizationId, transferId) {
    try {
      let response = null
      response = await axios.delete(`${organizationsUrl}/${organizationId}/graduands/${transferId}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }
}

const userService = new _DelinqentService()
export default userService
