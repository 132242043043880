let instance = null
const baseUrl = 'https://api.v1.dmis.nrs.gov.rw'
const authenticationUrl = '/authentication'
const userUrl = '/users'
const centerUrl = '/centers'
const locationUrl = '/locations'
const addressUrl = '/addresses'
const roleUrl = '/roles'
const privilegeUrl = '/privileges'
const positionUrl = '/positions'
const timeUnitUrl = '/time-units'
const statusUrl = '/statuses'
const centerTypeUrl = '/center-types'
const itemsUrl = '/items'
const documentTypeUrl = '/document-types'
const deviantActUrl = '/deviant-acts'
const delinquentUrl = '/delinquents'
const apprehensionUrl = '/apprehensions'
const decisionsUrl = '/decisions'
const screeningCommitteePositionsUrl = '/screening-committee-positions'
const educationLevelsUrl = '/education-levels'
const generalScreeningsUrl = '/general-screenings'
const medicalScreeningsUrl = '/medical-screenings'
const transfersUrl = '/transfers'
const assessmentUrl = '/assessments'
const assessmentTypesUrl = '/assessment-types'
const intakes = '/intakes'
const trainings = '/trainings'
const trainingClasses = '/training-classes'
const graduations = '/graduations'
const graduands = '/graduands'
const organizationTypes = '/organization-types'
const organizations = '/organizations'
const assessmentQuestions = '/behavior-assessment-questions'
const behaviorAssessmentResultsUrl = '/behavior-assessment-results'
const psychotherapyProgressNotesUrl = '/psychotherapy-progress-notes'
const assessmentPsychotherapyQuestionsUrl = '/multiple-choice-questions'
const reportUrl = '/reports'

class _Endpoint {
  constructor () {
    if (!instance) instance = this
    return instance
  }

  get baseUrl () {
    return baseUrl
  }

  get locationUrl () {
    return baseUrl + locationUrl
  }

  get authenticationUrl () {
    return baseUrl + authenticationUrl
  }

  get userUrl () {
    return baseUrl + userUrl
  }

  get centerUrl () {
    return baseUrl + centerUrl
  }

  get addressUrl () {
    return baseUrl + addressUrl
  }

  get roleUrl () {
    return baseUrl + roleUrl
  }

  get privilegeUrl () {
    return baseUrl + privilegeUrl
  }

  get positionUrl () {
    return baseUrl + positionUrl
  }

  get timeUnitUrl () {
    return baseUrl + timeUnitUrl
  }

  get statusUrl () {
    return baseUrl + statusUrl
  }

  get centerTypeUrl () {
    return baseUrl + centerTypeUrl
  }

  get itemsUrl () {
    return baseUrl + itemsUrl
  }

  get documentTypeUrl () {
    return baseUrl + documentTypeUrl
  }

  get deviantActUrl () {
    return baseUrl + deviantActUrl
  }

  get delinquentUrl () {
    return baseUrl + delinquentUrl
  }

  get apprehensionUrl () {
    return baseUrl + apprehensionUrl
  }

  get decisionsUrl () {
    return baseUrl + decisionsUrl
  }

  get screeningCommitteePositionsUrl () {
    return baseUrl + screeningCommitteePositionsUrl
  }

  get educationLevelsUrl () {
    return baseUrl + educationLevelsUrl
  }

  get generalScreeningsUrl () {
    return baseUrl + generalScreeningsUrl
  }

  get medicalScreeningsUrl () {
    return baseUrl + medicalScreeningsUrl
  }

  get transfersUrl () {
    return baseUrl + transfersUrl
  }

  get assessmentUrl () {
    return baseUrl + assessmentUrl
  }

  get assessmentTypesUrl () {
    return baseUrl + assessmentTypesUrl
  }

  get intakes () {
    return baseUrl + intakes
  }

  get trainings () {
    return baseUrl + trainings
  }

  get trainingClasses () {
    return baseUrl + trainingClasses
  }

  get graduations () {
    return baseUrl + graduations
  }

  get graduands () {
    return baseUrl + graduands
  }

  get organizationTypes () {
    return baseUrl + organizationTypes
  }

  get organizations () {
    return baseUrl + organizations
  }

  get assessmentQuestions () {
    return baseUrl + assessmentQuestions
  }

  get behaviorAssessmentResultsUrl () {
    return baseUrl + behaviorAssessmentResultsUrl
  }

  get psychotherapyProgressNotesUrl () {
    return baseUrl + psychotherapyProgressNotesUrl
  }

  get assessmentPsychotherapyQuestionsUrl () {
    return baseUrl + psychotherapyProgressNotesUrl + assessmentPsychotherapyQuestionsUrl
  }

  get reportUrl () {
    return baseUrl + reportUrl
  }
}

const Endpoint = new _Endpoint()
export default Endpoint
